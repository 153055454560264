import React from 'react'

const Footer = props => {

  let newDate = new Date()
  let year = newDate.getFullYear();

  return (
    <footer id="footer">
      <section>
        <h4>Social</h4>
        <ul className="icons">
          <li>
            <a
              href="https://twitter.com/leckerkrabbe"
              className="icon fa-twitter alt"
            >
              <span className="label">Twitter</span>
            </a>
          </li>
          <li>
            <a
              href="https://gitlab.com/Krabbe"
              className="icon fa-gitlab alt"
            >
              <span className="label">GitLab</span>
            </a>
          </li>
        </ul>
      </section>
      <p className="copyright">
        <a href="/">&copy; {year} Crabber Webservice</a> - <a href="/impressum">Impressum</a> - <a href="/datenschutzerklaerung">Datenschutz</a>
      </p>
    </footer>
  )
}

export default Footer

import React from 'react'
import { StaticQuery, graphql } from "gatsby"

import { stack as Menu } from 'react-burger-menu'

class BurgerMenu extends React.Component {
	showSettings (event) {
    event.preventDefault();    
  }

  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                menuLinks {
                  name
                  url
                }
              }
            }
          }
        `}
        render={(data) =>(
          <Menu width={ '15em' }>
            { data.site.siteMetadata.menuLinks.map((nav) =>
              <a key={nav.url} className="menu-item" href={`/` + nav.url}>{nav.name}</a>
            ) }
          </Menu>
        )}
      />
    )
  }

}

export default BurgerMenu